<template>
  <div id="pageFuncionario">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg12 sm12 xs12>
          <funcionario-table></funcionario-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import FuncionarioTable from "@/components/funcionario/tableFuncionario"
export default {
  components: {
    FuncionarioTable
  }
}
</script>
